const React = import("react");
const ReactDom = import("react-dom");

export const render = (element, Component, props) => {
  Promise.all([Component, React, ReactDom]).then(
    ([
      { default: Component },
      { default: React },
      { default: ReactDom },
    ]) => {
      ReactDom.render(<Component {...props} />, element);
    }
  );
};

export const unmount = (element) => {
  Promise.all([ReactDom]).then(
    ([
      { default: ReactDom },
    ]) => {
      ReactDom.unmountComponentAtNode(element);
    }
  );
};